class TolstoyApp extends HTMLElement {
  constructor () {
    super ()
    const block = this.querySelector('.js-block-app')
    this.videoEls = this.querySelectorAll('video')
    this.carouselEls = this.querySelector('.tolstoy-carousel-videos-container')

    if (this.videoEls && this.carouselEls) {
      if (this.videoEls) {
        this.videoEls.forEach((video) => {
          video.addEventListener('play', () => {
            video.parentElement.classList.add('play')
          })
          video.addEventListener('pause', () => {
            video.parentElement.classList.remove('play')
          })
        })
      }

      if (this.carouselEls) {
        this.updateProgressBar(this.carouselEls)
        window.addEventListener("resize", this.updateProgressBar(this.carouselEls));

        this.carouselEls.addEventListener('scroll', () => {
          this.updateProgressBar(this.carouselEls)
        })
      }
    } else {
      let isLoaded = false

      const callback = (mutationList, observer) => {
        for (const mutation of mutationList) {
          if (mutation.type === "childList") {
            isLoaded = true
          }

          if (isLoaded) {
            const videos = mutation.target.querySelectorAll('video')
            const carousel = mutation.target.querySelector('.tolstoy-carousel-videos-container')

            videos.forEach((video, index) => {
              video.addEventListener('play', () => {
                video.parentElement.classList.add('play')
              })

              video.addEventListener('pause', () => {
                video.parentElement.classList.remove('play')
              })
            })

            if (carousel) {
              this.updateProgressBar(carousel)

              window.addEventListener("resize", this.updateProgressBar(carousel));

              carousel.addEventListener('scroll', () => {
                this.updateProgressBar(carousel)
              })
            }
          }
        }
      };
      const observer = new MutationObserver(callback);
      observer.observe(block, { childList: true, subtree: true });
    }
  }

  updateProgressBar(el) {
    const scrollPercentage = 100 * el.scrollLeft / (el.scrollWidth - el.clientWidth);
    const formatScrollPercentage = 'calc(' + scrollPercentage.toFixed(0) + '% + 1px)'

    if (formatScrollPercentage) {
      scrollPercentage === 0 ? this.classList.add('is-start') : this.classList.remove('is-start')
      scrollPercentage === 100 ? this.classList.add('is-end') : this.classList.remove('is-end')

      if (scrollPercentage > 0 && scrollPercentage < 101) {
        setTimeout(() => {
          this.style.setProperty('--progess-bar-width', formatScrollPercentage)
        }, 300);
      }
    }
  }
}

window.customElements.define('tolstoy-app', TolstoyApp)
